<template>
  <div class="dropdown-wrapper" :class="{ 'included-label': label }">
    <span class="label-name" v-if="label">{{ label }}</span>
    <div
      class="input-wrapper"
      :class="{ 'exist-value': value, 'opened-options': isOpenOptions, disabled: disabled }"
      @click="toggleOptionList"
    >
      <label :for="id">
        <input
          :id="id"
          type="text"
          class="search-input"
          :value="getOptionName(value)"
          :placeholder="placeholder"
          @input="onChange"
          @keyup.enter="onKeyUpEnter"
          disabled
        />
      </label>
      <span class="input-right-icon" data-icon="input-right-icon">
        <vue-material-icon name="keyboard_arrow_down" />
      </span>
    </div>
    <div class="dropdown-option" v-show="isOpenOptions" @mouseleave="isOpenOptions = false">
      <ul>
        <li v-for="(option, index) in optionItemsData" @click="onClickOption(option)" :key="index">
          {{ getOptionName(option) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropDownBox',
  props: {
    id: {
      type: String,
      require: false,
      default: '',
    },
    value: {
      type: String,
      require: false,
      default: '',
    },
    placeholder: {
      type: String,
      require: false,
      default: '',
    },
    optionItemsData: {
      type: Array,
      require: false,
    },
    optionNames: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isOpenOptions: false,
      selectedOption: '',
    };
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    getOptionName(option) {
      if (this.optionNames) {
        return this.optionNames[option];
      }
      return option;
    },
    onKeyUpEnter() {
      this.$emit('keyupenter');
    },
    onChange($event) {
      this.$emit('input', $event.target.value);
    },
    toggleOptionList() {
      this.isOpenOptions = !this.isOpenOptions;
    },
    onClickOption(option) {
      this.toggleOptionList();
      this.selectedOption = option;
      this.$emit('selectedOption', option);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'DropDown';
</style>

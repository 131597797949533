var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown-wrapper", class: { "included-label": _vm.label } },
    [
      _vm.label
        ? _c("span", { staticClass: "label-name" }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "input-wrapper",
          class: {
            "exist-value": _vm.value,
            "opened-options": _vm.isOpenOptions,
            disabled: _vm.disabled,
          },
          on: { click: _vm.toggleOptionList },
        },
        [
          _c("label", { attrs: { for: _vm.id } }, [
            _c("input", {
              staticClass: "search-input",
              attrs: {
                id: _vm.id,
                type: "text",
                placeholder: _vm.placeholder,
                disabled: "",
              },
              domProps: { value: _vm.getOptionName(_vm.value) },
              on: {
                input: _vm.onChange,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onKeyUpEnter.apply(null, arguments)
                },
              },
            }),
          ]),
          _c(
            "span",
            {
              staticClass: "input-right-icon",
              attrs: { "data-icon": "input-right-icon" },
            },
            [
              _c("vue-material-icon", {
                attrs: { name: "keyboard_arrow_down" },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpenOptions,
              expression: "isOpenOptions",
            },
          ],
          staticClass: "dropdown-option",
          on: {
            mouseleave: function ($event) {
              _vm.isOpenOptions = false
            },
          },
        },
        [
          _c(
            "ul",
            _vm._l(_vm.optionItemsData, function (option, index) {
              return _c(
                "li",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.onClickOption(option)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.getOptionName(option)) + " ")]
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }